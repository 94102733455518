const columns = [
  {
    label: "达人昵称(英文)",
    prop: "user_name",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "达人昵称(原文)",
    prop: "nick_name",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "申请状态",
    prop: "status",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "到期时间",
    prop: "effective_end_date_time",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "分成比例(%)",
    prop: "commission_proportion",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "备注",
    prop: "remark",
    align: "center",
    width: 200,
    tooltip: "",
    sortable: false,
    fixed: false,
    noFormat: true,
  },
];
export default columns;
